export const MenuData = [
	{
		title: 'About',
		path: '/about-aretetic-solutions',
	},
	{
		title: 'Products',
		path: '/aretetic-products'
	},
	{
		title: 'Services',
		path: '/aretetic-solutions-services',
	},
	{
		title: 'Contact',
		path: '/contact-us'
	},
]